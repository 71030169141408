<template>
    <el-table v-if="employeesData.length > 0" :data="employeesData">
        <el-table-column :label="$t('employees.name')" prop="name" sortable :formatter="nameFormatter" />
        <el-table-column :label="$t('employees.street')" prop="address.street" sortable />
        <el-table-column :label="$t('employees.city')" prop="address.city" sortable />
        <el-table-column :label="$t('employees.email')" prop="email" sortable width="300" />
        <el-table-column :label="$t('employees.telephone')" prop="telephone" sortable width="150" />
        <el-table-column :label="$t('employees.manager')" prop="manager" sortable />
        <el-table-column width="100">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link
                            :to="`/employees/${scope.row.uuid}/information`"
                            class="el-button el-tooltip el-button--primary el-button--mini"
                        >
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('employees.bring_back')" placement="top">
                        <el-button size="mini" type="warning" @click="handleRestore(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'redo']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('employees.delete')" placement="top">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        employeesData: {
            type: Array,
            default() { return []; },
        },
    },

    methods: {
        handleRestore(uuid) {
            this.$emit('handleRestore', uuid);
        },

        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },

        nameFormatter(row) {
            return `${row.name} ${row.surname}`;
        },
    },
};
</script>
